import * as React from 'react';

import {WebcatMeta} from '../items/item.class';

interface TopCategoriesProps {
    categories: WebcatMeta[];
    id: string;
    title: string;
}

export const TopCategories = ({categories, id, title}: TopCategoriesProps) => {
    if (!categories) {
        return <></>;
    }
    return (
        <div
            className="tw-mb-3"
            data-e2e={id}
        >
            <h3 className="h4-lockup">
                {title}
                <span>
                    <a href="/browse">View All</a>
                </span>
            </h3>
            <div className="tw-mb-4">
                <div className={`tw-grid tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-6 tw-gap-4`}>
                    {categories.map((category, index) => (
                        <div
                            key={index}
                            className="tw-mb-3 tw-relative"
                        >
                            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-border tw-p-3 tw-h-full tw-text-center">
                                <img
                                    alt={category.name}
                                    className="tw-mb-3"
                                    height="120"
                                    src={`/${category.imageAssetLargeThumb || 'thumb/120/noimage.jpg'}`}
                                    width="120"
                                />
                                <a
                                    className="after:tw-absolute after:tw-inset-0"
                                    href={`/browse/${category.categoryPath}`}
                                >
                                    {category.name}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
