import * as React from 'react';
import {faCheckCircle, faClipboardList, faHistory, faList, faBasketShopping} from '@fortawesome/pro-solid-svg-icons';
import {faListAlt} from '@fortawesome/free-solid-svg-icons';

import {MobileTile} from './MobileTile';
import {User} from '../users/user.class';

interface MobileTilesProps {
    user: User;
}

export const MobileTiles = ({user}: MobileTilesProps) => {
    return (
        <>
            {user.hasRepairOrder() && (
                <MobileTile
                    href="/jobcosting"
                    icon={faClipboardList}
                    label="Repair Orders"
                />
            )}
            {user.hasOrderApprovalAccess && (
                <MobileTile
                    href="/orders/approval"
                    icon={faCheckCircle}
                    label="Order Approval"
                />
            )}
            <MobileTile
                href="/orders"
                icon={faListAlt}
                label="Order Review"
            />
            <MobileTile
                href="/order-history"
                icon={faHistory}
                label="Order History"
            />
            <MobileTile
                href="/order-history-items"
                icon={faBasketShopping}
                label="Purchased Products"
            />
            <MobileTile
                href="/lists"
                icon={faList}
                label="My Lists"
            />
        </>
    );
};
