import {FetchService} from '../fetch/fetch.service';
import {GetShipSaverTotalRes} from '../../shared/reports/report.class';

export class ReportsService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Gets ship saver total for user
     */
    public getShipSaverTotal() {
        return this._fetchService.get<GetShipSaverTotalRes>(`/api/reports/getShipSaverTotal`);
    }
}
